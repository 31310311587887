<template>
  <div
    v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_SIGN_LOAN.value, path: $route.path}">
    <v-overlay :value="layouts.data.loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div
      ref="loanAgreement"
      id="loan-agreement"
      :style="`height: ${height -6}px;`">
      <iframe
        class="embed-iframe"
        id="signature-iframe"
        :src="layouts.data.loanAgreementUrl"></iframe>
    </div>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { DataType, Sloth } from '@/assets/js/util'

export default {
  name: 'sign-loan-agreement',
  components: {},
  data: () => ({
    assist: {
      dataType: DataType
    },
    height: '0',
    slothUrl: '',
    layouts: {
      data: {
        loanAgreementUrl: '',
        loading: false
      }
    }
  }),
  mounted () {
    const element = document.getElementById('loan-agreement')
    const distanceToTop = element.offsetTop
    this.height = document.body.clientHeight - distanceToTop

    const loanNo = this.$store.getters.getLoan.loanNo
    this.initWebSocket(loanNo)
    this.sendLoanAgreement(loanNo)

    this.layouts.data.loading = true
    const iframe = document.getElementById('signature-iframe')
    iframe.onload = () => {
      this.layouts.data.loading = false
    }
  },
  methods: {
    initWebSocket (loanNo) {
      const $this = this
      let data = {}
      this.judgeEnvironment()
      $this.sloth = new Sloth({
        url: $this.slothUrl,
        mode: 'ClientSide',
        wsid: loanNo
      })
      $this.sloth.init()
      $this.sloth.on('message', function (message) {
        switch (message.data.ticket) {
          case '2060': {
            data = message.data
            if (data.signatureStatus === DataType.SignStatusEnum.SIGNED.value) {
              $this.$router.push('/notification/102')
            }
            break
          }
          default:
            break
        }
      })
    },
    judgeEnvironment () {
      switch (process.env.VUE_APP_TITLE) {
        case 'develop':
          this.slothUrl = 'wsxch-dolphin.sztnb.com'
          break
        case 'production':
          this.slothUrl = 'wsxch-dolphin.sztus.com'
          break
        default :
          break
      }
    },
    sendLoanAgreement (loanNo) {
      const input = {
        notificationType: 1,
        destination: loanNo
      }
      OnlineApi.sendLoanAgreement(input, (result) => {
        result += '?embed=1'
        this.layouts.data.loanAgreementUrl = result
      }, (failResult) => {
        this.$store.commit('setPopupInformation', {
          message: failResult.message
        })
      })
    }
  }
}
</script>

<style scoped lang="sass">
.embed-iframe
  width: 100%
  height: 100%
  border: none

</style>
